import React, { type FC, useState } from 'react';
import dynamic from 'next/dynamic';
import {
    type SubscriptionAppView,
    type AccountAppView,
    type AccountAppViewItem
} from 'bb/account/types';
import { type Notification as NotificationType } from 'bb/common/types';
import { match } from 'bb/utils';

const DynamicAccountNotification = dynamic(
    () =>
        import('./AccountNotification').then((mod) => mod.AccountNotification),
    { ssr: false }
);

const DynamicFloatingNotification = dynamic(
    () =>
        import('./FloatingNotification').then(
            (mod) => mod.FloatingNotification
        ),
    { ssr: false }
);

const DynamicGeneralNotification = dynamic(
    () =>
        import('./GeneralNotification').then((mod) => mod.GeneralNotification),
    { ssr: false }
);

type NotificationProps = {
    notifications?: NotificationType[];
    typesAllowed: string[];
    accountAppView?: AccountAppView<AccountAppViewItem> | SubscriptionAppView;
};

type MatchNotificationType = {
    notification: NotificationType;
    typesAllowed: string[];
};

const isGiftcard = ({ notification, typesAllowed }: MatchNotificationType) =>
    notification.type === 'giftcard' &&
    typesAllowed.includes(notification.type);

const isAccount = ({ notification, typesAllowed }: MatchNotificationType) =>
    notification.type === 'account' && typesAllowed.includes(notification.type);

const isProfile = ({ notification, typesAllowed }: MatchNotificationType) =>
    notification.type === 'profile' && typesAllowed.includes(notification.type);

const isGeneral = ({ notification, typesAllowed }: MatchNotificationType) =>
    notification.type === 'general' && typesAllowed.includes(notification.type);

const isPayment = ({ notification, typesAllowed }: MatchNotificationType) =>
    notification.type === 'payment' && typesAllowed.includes(notification.type);

const isActiveDate = (notification: NotificationType) => {
    const now = new Date();
    const startTime = new Date(notification.startTime);
    const endTime = new Date(notification.endTime);
    return startTime < now && endTime > now;
};

const shouldShowNotifcation = (
    notification: NotificationType,
    appviewItem?: AccountAppView<AccountAppViewItem> | SubscriptionAppView
) => {
    const expiringProduct = Boolean(
        appviewItem?._embedded?.items?.find?.(
            (item) => item.type === 'ExpiringSubscriptionProductNotification'
        )
    );
    const showExpiringProductNotification =
        notification.isExpiringProductNotification && expiringProduct;
    const showRegularNotification = !notification.isExpiringProductNotification;

    if (showExpiringProductNotification || showRegularNotification) {
        return isActiveDate(notification);
    }
    return false;
};

export const Notification: FC<NotificationProps> = ({
    notifications,
    typesAllowed,
    accountAppView
}) => {
    const [show, setShow] = useState(true);
    const onClose = () => setShow(false);

    return (
        <>
            {notifications?.map((notification) =>
                shouldShowNotifcation(notification, accountAppView)
                    ? match({ notification, typesAllowed })
                          .on(isGiftcard, () => (
                              <DynamicFloatingNotification
                                  key={`${
                                      notification.title
                                  }-${notification?.onlyShowFor?.toString()}`}
                                  notification={notification}
                                  onClose={onClose}
                                  show={show}
                              />
                          ))
                          .on(isAccount, () => (
                              <DynamicAccountNotification
                                  key={`${
                                      notification.title
                                  }-${notification?.onlyShowFor?.toString()}`}
                                  notification={notification}
                                  show
                              />
                          ))
                          .on(isProfile, () => (
                              <DynamicAccountNotification
                                  key={`${
                                      notification.title
                                  }-${notification?.onlyShowFor?.toString()}`}
                                  notification={notification}
                                  show
                              />
                          ))
                          .on(
                              isGeneral,
                              () =>
                                  show && (
                                      <DynamicGeneralNotification
                                          key={`${
                                              notification.title
                                          }-${notification?.onlyShowFor?.toString()}`}
                                          notification={notification}
                                          onClose={onClose}
                                      />
                                  )
                          )
                          .on(
                              isPayment,
                              () =>
                                  show && (
                                      <DynamicGeneralNotification
                                          key={`${
                                              notification.title
                                          }-${notification?.onlyShowFor?.toString()}`}
                                          notification={notification}
                                          onClose={onClose}
                                      />
                                  )
                          )
                          .otherwise(() => null)
                    : null
            )}
        </>
    );
};
