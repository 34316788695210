import React from 'react';
import classNames from 'classnames';
import { Button, type ButtonProps } from 'bb/ui/Button';
import { SpinnerIcon } from 'bb/ui/Icons';
import css from './fallbackButton.module.scss';

export type FallbackButtonProps = Omit<ButtonProps, 'startAdornment'>;

export const FallbackButton = (props: FallbackButtonProps) => {
    const { className, ...restProps } = props;
    return (
        <Button
            className={classNames(css.root, className)}
            startAdornment={<SpinnerIcon />}
            {...restProps}
        />
    );
};
